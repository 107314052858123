import { Link } from 'gatsby'
import React from 'react'

const Footer = props => (
  <footer id="footer">
    <p className="copyright">
      &copy; Asgaard Software. All rights reserved. | Design by: <a href="https://html5up.net">HTML5 UP</a>, modified by Asgaard Software.
    </p>
  </footer>


)

export default Footer
